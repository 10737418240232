let colors = {
    'hausastrid': '#A82D03',
    'suedstrand': '#3F7BA1',
    'hausnina': '#4688BE'
};

export const state = () => ({
    fewoDatas: {
        'suedstrand204': [
            {
                "logotitle": "Südstrand Ferienwohnung 204",
                "logo": "2020-01-27-logo-suedstrand.svg",
                "color": colors.suedstrand,
                "headline": "Ferienwohnungen mit Ostseeblick am Südstrand",
                "description": "Ferienwohnungen für 1-4 Personen am Südstrand der Insel Fehmarn an der Ostsee.",
                "headerimgMobile": "suedstrandwohnung204/buehne_768x768@2x.jpg",
                "headerimg": "suedstrandwohnung204/buehne_1000x350@2x.jpg",
                "qm": "49 m²",
                "price": "55,00 €",
                "footer": '<p>Besuchen Sie auch unsere Ferienwohnungen im <a href="https://www.hausnina.de/" target="_blank"><span class="icon-house"></span>Haus Nina</a> oder im <a href="https://www.hausastrid.de/" target="_blank"><span class="icon-house"></span>Haus Astrid</a> in Rostock/Warnemünde</p>',
                "fewos": [
                    {
                        "gallery": [
                            {
                                "caption": "Wohnzimmer",
                                "img": 'suedstrandwohnung204/gallery/01_204_wz.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung204/gallery/01_204_wz.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung204/gallery/01_204_wz.jpg?resize').src,
                            },
                            {
                                "caption": "Wohnzimmer",
                                "img": 'suedstrandwohnung204/gallery/02_204_wz.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung204/gallery/02_204_wz.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung204/gallery/02_204_wz.jpg?resize').src,
                            },
                            {
                                "caption": "Wohnzimmer",
                                "img": 'suedstrandwohnung204/gallery/03_204_wz.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung204/gallery/03_204_wz.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung204/gallery/03_204_wz.jpg?resize').src,
                            },
                            {
                                "caption": "Bad",
                                "img": 'suedstrandwohnung204/gallery/04_204_bad.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung204/gallery/04_204_bad.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung204/gallery/04_204_bad.jpg?resize').src,
                            },
                            {
                                "caption": "Küche",
                                "img": 'suedstrandwohnung204/gallery/05_204_kueche.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung204/gallery/05_204_kueche.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung204/gallery/05_204_kueche.jpg?resize').src,
                            },
                            {
                                "caption": "Schlafzimmer",
                                "img": 'suedstrandwohnung204/gallery/06_204_sz.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung204/gallery/06_204_sz.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung204/gallery/06_204_sz.jpg?resize').src,
                            },
                            {
                                "caption": "Grundriss",
                                "img": 'suedstrandwohnung204/gallery/02_204.jpg',
                                "thumb": require('~/assets/img/suedstrandwohnung204/gallery/02_204.jpg?resize').src,
                                "src": require('~/assets/img/suedstrandwohnung204/gallery/02_204.jpg?resize').src,
                            }
                        ],
                        "title": "Wohnung Nr. 204",
                        "quote": "„Behaglich wie in einem Nest“ - eine gemütliche Atmosphäre erwartet Sie in der Seemöwe",
                        "description": "<p>Die 49 m² große Ferienwohnung eignet sich hervorragend für 1-4 Personen. Sie liegt in der 2. Etage und bietet Ihnen neben Wohnzimmer mit Doppelbett, ein Schlafzimmer mit 2 Einzelbetten, eine Küche und ein Bad mit Dusche. Der etwa 5 m² große Balkon bietet einen herrlichen Blick auf das Meer. Bei Bedarf stehen Ihnen zusätzlich Babybett und Hochstuhl zur Verfügung.</p><p><strong>Ausstattung:</strong><br/>Geschirrspüler, Kabelfernsehen, Radio, Balkon, Fahrstuhl, Parkplatz, Tiere erlaubt, WLan vorhanden. Handtücher und Bettwäsche können bei der Schlüsselübergabe kostenpflichtig\n" +
                            "gemietet werden.</p>",
                        "price": "55,00 €",
                        "qm": "49 m²",
                        "person": "max. 4",
                        "priceNight": "55,00 - 95,00 €",
                        "dog": "10,00 €",
                        "cleaning": "75,00 €"
                    },
                ],
                "seasons": [
                    {
                        "title": "Nebensaison",
                        "season": [
                            {
                                "time": "01.01.24 - 22.03.24",
                            },
                            {
                              "time": "04.11.24 - 19.12.24",
                            }
                        ],
                    },
                    {
                        "title": "Zwischensaison",
                        "season": [
                            {
                                "time": "22.03.24 - 15.06.24",
                            },
                            {
                                "time": '15.09.24 - 03.11.24'
                            },
                            {
                                "time": '20.12.24 - 05.01.25'
                            }
                        ],
                    },
                    {
                        "title": "Hauptsaison",
                        "season": [
                            {
                                "time": "15.06.24 - 15.09.24",
                            }
                        ],
                    }
                ],
                "priceinfo": "<p>Die genannten Preise beziehen sich auf eine Übernachtung pro Ferienwohnung. Im Mietpreis enthalten sind Strom und Wasser.</p>",
                "locationinfo": "Planen Sie Ihre Anreise mit Google Maps. <br/>Wir wünschen Ihnen eine gute Fahrt.",
                "address": "<p>Strandhaferweg 3<br/>23769 Fehmarn<br/>Deutschland</p>",
                "mapslink": "https://www.google.de/maps/dir//Strandhaferweg+3,+23769+Fehmarn/@54.4112689,11.1250182,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47ad7b1b7e60aff1:0x34ffc350cab5f6e4!2m2!1d11.1952291!2d54.4112893",
                "maps": "suedstrandwohnung204/map-suedstrand.jpg",
                "contactAddress": "<p>Ingeborg Schäfer<br/>" +
                    "                        Edelhofdamm 6<br/>" +
                    "                        13465 Berlin<br/><br/>Tel: + 49 (0) 30 401 63 84<br/>" +
                    "                        Fax: + 49 (0) 30 401 02 113<br/><br/></p>",
                "contactEMail": "kontakt@suedstrand-wohnung.de",
                "contactHeadline": "Buchen Sie Ihre Ferienwohnung am Südstrand",
                "contactInfos": [
                    {
                        "icon": "icon-direkter-Blick-auf-die-Ostsee.svg",
                        "title": "Direkter Blick auf die Ostsee"
                    },
                    {
                        "icon": "icon-raum-zu-freien-entfaltung.svg",
                        "title": "Ideal für Familien"
                    },
                    {
                        "icon": "icon-private.svg",
                        "title": "Privater Anbieter"
                    },
                    {
                        "icon": "icon-keine-versteckten-Kosten.svg",
                        "title": "Keine versteckten Kosten"
                    }
                ]
            }
        ]
    }
})
